@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

/* Sidebar styles for collapsed/expanded states */
@media (min-width: 1024px) {
  .sidebar {
    width: 64px;
    transition: width 0.3s ease;
  }
  
  .sidebar:hover {
    width: 180px;
  }
  
  /* Minimal margins - reduced by 50% from previous values */
  .sidebar + div {
    margin-left: 5px; /* Reduced from 10px (50% reduction) */
    transition: margin-left 0.3s ease;
  }
  
  .sidebar:hover + div {
    margin-left: 10px; /* Reduced from 20px (50% reduction) */
  }
  
  .sidebar .sidebar-text {
    display: none;
    white-space: nowrap;
  }
  
  .sidebar:hover .sidebar-text {
    display: block;
  }
  
  .sidebar .sidebar-logo {
    display: none;
  }
  
  .sidebar:hover .sidebar-logo {
    display: block;
  }
  
  .sidebar .sidebar-icon {
    display: block;
  }
  
  .sidebar:hover .sidebar-icon {
    display: none;
  }
  
  /* Bottom section */
  .sidebar .bottom-container {
    width: 64px;
    transition: width 0.3s ease;
  }
  
  .sidebar:hover .bottom-container {
    width: 180px;
  }
  
  /* Icon position in collapsed state */
  .sidebar .menu-item {
    justify-content: center;
  }
  
  .sidebar:hover .menu-item {
    justify-content: flex-start;
  }
}